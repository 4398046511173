import React, { useState, useEffect } from 'react';
import { SubHeader, TranscationTable, successAlert, errorAlert, FileUploadWrapper } from '../../uiComponents';
import { AssignmentView, AssignmentViewWrapper } from './AssignmentView';
import { InterpreterBlock } from './InterpreterBlock';
import { connect } from 'react-redux';
import { Loader } from '../Loader';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  IRightDynamicContent,
  userTypeEnums,
  IReport,
  IFeedbackPayload,
  statusReasonEnmus,
  routeEnums,
  IErrorGeneral,
} from '../../interface';
import {
  getAssignmentDetails,
  markFavInterpreter,
  resetMarkFav,
  cancelAssignment,
  resetAssignment,
  reportAssignment,
  getFeedbackLookup,
  addFeedback,
  getDocumentlookup
} from '../../redux/action';
import { useAppDispatch } from '../../config/hook';
import { formatDate, checkIsSameOrbefore, checkEndDateWithCurrent } from '../../utils';
import { Travel } from './Travel';
import { Feedback } from './Feedback';
import { useTranslation } from 'react-i18next';
import { DocumentListTableWrapper } from '../DocumentList';

export const Assignment = (props: any) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useAppDispatch();
  const [rightContent, setRightContent] = useState<IRightDynamicContent>({ displayContent: `${location.state}` });
  const [buttonDisplay, setButtonDisplay] = useState<boolean>(false);
  const [cancelBtnVisible, setCancelBtnVisible] = useState<boolean>(false);
  const [assignment, setAssignment] = useState<any>({});
  const [interpreter, setInterpreter] = useState<any>({});
  const [transaction, setTranscation] = useState([]);
  const [document, setDocument] = useState([]);
  const [travel, setTravel] = useState({});
  const [feedback, setFeedback] = useState(false);
  const [minimalFeedback, setMinimalFeedback] = useState({});
  const [cancelErrMessage, setCancelErrMessage] = useState(null);
  const [editNotAllowed, setEditNotAllowed] = useState<IErrorGeneral>({ error: false, errorMessage: '' });
  const [isAssignmentEnd, setIsAssignmentEnd] = useState<boolean>(false);
  const [feedbackLookup, setFeedbackLoopup] = useState<any>([]);
  const [documentLookup, setDocumentLookup] = useState([]);
  const getParams = useParams();
  const leftContent = {
    title: t('assignment.title'),
    breadcrumb: `Home / ${t('assignment.title')}`,
  };

  const requestPayload = {
    assignmentNo: getParams.id,
    contactType: props?.userType === userTypeEnums.CUSTOMER ? userTypeEnums.CUSTOMER : userTypeEnums.INTERPRETER,
  };

  useEffect(() => {
    getAssignmentDetails(requestPayload, dispatch);
    getDocumentlookup(dispatch);
  }, []);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      setAssignment(props?.response?.data?.assignment ?? {});
      setTranscation(props?.response?.data?.assignmentTransaction ?? []);
      setDocument(props?.response?.data?.documentList ?? []);
      setButtonDisplay(
        checkIsSameOrbefore(props?.response?.data?.assignment?.startTime) && props?.userType === userTypeEnums.CUSTOMER
          ? true
          : false,
      );
      handleCancel(props?.response?.data?.assignment?.startTime, props?.response?.data?.assignment?.assignmentStatus);
      handleIsFeedback(props?.response?.data?.assignment?.endTime);
      setRightContent({
        displayContent: `${formatDate(props?.response?.data?.assignment?.startTime)} ${
          props?.response?.data?.assignment?.account ?? ''
        }`,
      });
      if (props?.userType === userTypeEnums.CUSTOMER) {
        let interpreterUpdate = {
          ...props?.response?.data?.interpreterDetails,
          actualStartTime: props?.response?.data?.assignment?.actualStartTime,
          actualEndTime: props?.response?.data?.assignment?.actualEndTime,
        };
        setInterpreter(interpreterUpdate);
        let parseFeedback = {
          isFeedbackProvided: props?.response?.data?.assignment?.isFeedbackProvided,
          phaseType: props?.response?.data?.assignment?.phaseType,
          resolution: props?.response?.data?.assignment?.resolution,
        };
        setMinimalFeedback(parseFeedback);
      } else {
        let travelExpense = {
          ...props?.response?.data?.assignment,
          fixedTravelCost: props?.response?.data?.assignment?.fixedTravelCost,
          kilometers: props?.response?.data?.assignment?.kilometers,
          travelTime: props?.response?.data?.assignment?.travelTime,
          travelTimeEvening: props?.response?.data?.assignment?.travelTimeEvening,
          actualStartTime: props?.response?.data?.assignment?.actualStartTime,
          actualEndTime: props?.response?.data?.assignment?.actualEndTime,
          travelExpenses: props?.response?.data?.assignmentTravelExpenses,
        };
        setTravel(travelExpense);
      }
      resetAssignment(dispatch);
    }
  }, [props.response]);

  useEffect(() => {
    if (Object.keys(props?.markFav).length > 0) {
      if (props?.markFav?.message === 'Success') {
        successAlert(t('assignment.interpreteraddedasfavourite'));
      } else {
        errorAlert(props?.markFav?.message);
      }
      resetMarkFav(dispatch);
    }
  }, [props.markFav]);

  useEffect(() => {
    if (Object.keys(props?.cancelResponse).length > 0) {
      if (props?.cancelResponse?.status === 200) {
        getAssignmentDetails(requestPayload, dispatch);
      } else {
        errorAlert(props?.cancelResponse?.message);
        resetAssignment(dispatch);
      }
    }
  }, [props.cancelResponse]);

  useEffect(() => {
    if (Object.keys(props?.report).length > 0) {
      if (props?.report?.status === 200) {
        getAssignmentDetails(requestPayload, dispatch);
      } else {
        errorAlert(props?.report?.message);
      }
    }
  }, [props.report]);

  useEffect(() => {
    if (Object.keys(props?.lookupFeedback).length > 0) {
      if (props?.lookupFeedback?.status === 200) {
        setFeedbackLoopup(props?.lookupFeedback?.data?.qualityCaseTypes);
      } else {
        errorAlert(props?.lookupFeedback?.message);
      }
    }
  }, [props.lookupFeedback]);

  useEffect(() => {
    if (Object.keys(props?.lookupDocument).length > 0) {
      if (props?.lookupDocument?.status === 200) {
        setDocumentLookup(props?.lookupDocument?.data?.documentTypes);
      } else {
        errorAlert(props?.lookupDocument?.message);
      }
    }
  }, [props.lookupDocument]);

  useEffect(() => {
    if (Object.keys(props?.submitFeedback).length > 0) {
      if (props?.submitFeedback?.status === 200) {
        window.location.reload();
      } else {
        errorAlert(props?.submitFeedback?.message);
      }
    }
  }, [props.submitFeedback]);

  const handleCancel = (startTime, status) => {
    if (props?.userType === userTypeEnums.CUSTOMER) {
      if (checkIsSameOrbefore(startTime) && status !== statusReasonEnmus.CANCELLEDPROCESSED) {
        setCancelBtnVisible(true);
      } else {
        setCancelBtnVisible(false);
      }
    }
  };

  const handleIsFeedback = (endTime) => {
    const feedbackVisible = checkEndDateWithCurrent(endTime);
    if (feedbackVisible && props?.userType === userTypeEnums.CUSTOMER) {
      setIsAssignmentEnd(true);
    }
  };

  const onFavClick = () => {
    const requestPayload = {
      customerId: assignment?.customerId,
      interpreterId: interpreter?.id,
      orderByContactId: assignment?.orderByContactNameId,
    };
    markFavInterpreter(requestPayload, dispatch);
  };

  const onCancelClick = () => {
    if (assignment?.isCancelPossible) {
      const requestPayload = assignment?.assignmentId;
      cancelAssignment(requestPayload, dispatch);
    } else if (assignment?.isCancelPossible === false) {
      if (assignment?.assignmentType === 'Fremmøte') {
        setCancelErrMessage(t('assignment.cancelassignmenttoonearertostart'));
      } else {
        setCancelErrMessage(t('assignment.cancelassignmentalreadystarted'));
      }
      setTimeout(() => {
        setCancelErrMessage(null);
      }, 7000);
    }
  };

  const onEditClick = () => {
    if (assignment.assignmentStatus === statusReasonEnmus.NEW) {
      navigate(routeEnums.ORDER, { state: assignment });
    } else {
      setEditNotAllowed({ error: true, errorMessage: t('assignment.editNotAllowed') });
    }
  };

  const onFeedbackClick = () => {
    setFeedback(true);
    if (assignment.isFeedbackProvided === false) {
      getFeedbackLookup(null, dispatch);
    }
  };

  const onSubmitFeedback = (payload) => {
    const getSelectedFeedback = feedbackLookup.find((element) => {
      if (element.id === payload.qualityType) {
        return element;
      }
    });
    const requestPayload: IFeedbackPayload = {
      name: getSelectedFeedback?.name,
      typeId: getSelectedFeedback?.id,
      assignmentId: assignment?.assignmentId,
      customerId: assignment?.customerId,
      interpreterId: interpreter?.id,
      orderByContactId: assignment?.orderByContactNameId,
      feedbackFromCustomer: payload.freefeedbackText,
    };
    addFeedback(requestPayload, dispatch);
  };

  const handleReportClick = (value: IReport) => {
    reportAssignment(value, dispatch);
  };

  if (props.loading) return <Loader />;

  return (
    <div className="bg_blue p-3 content">
      {assignment?.assignmentStatus === statusReasonEnmus.CANCELLEDPROCESSED && (
        <div className="cancel_header">
          <button type="button" className="btn w-100">
            {`${t('assignment.cancelMessage')} ${formatDate(assignment?.cancelledDate)}`}
          </button>
        </div>
      )}
      {cancelErrMessage !== null && (
        <div className="cancel_header">
          <button type="button" className="btn w-100">
            {cancelErrMessage}
          </button>
        </div>
      )}
      {editNotAllowed.error && (
        <div className="cancel_header">
          <button type="button" className="btn w-100">
            {editNotAllowed.errorMessage}
          </button>
        </div>
      )}
      <div className="mt-2 pb-2">
        <SubHeader
          buttonDisplay={buttonDisplay}
          isDisplay
          leftContent={leftContent}
          rightContent={rightContent}
          cancelBtnDisplay={cancelBtnVisible}
          cancelOnClick={onCancelClick}
          favButtonDisplay={props?.userType === userTypeEnums.CUSTOMER ? true : false}
          favOnClick={onFavClick}
          feedbackBtnDisplay={isAssignmentEnd}
          feedbackOnClick={onFeedbackClick}
          editOnClick={onEditClick}
        />
        <div className="p-3">
          <div className="row">
            <div className="col-12 col-xl-7 col-lg-7 col-md-12 col-sm-12">
              <AssignmentViewWrapper assignmentDetails={assignment} usertype={props.userType} />
            </div>
            <div className="col-12 col-xl-5 col-lg-5 col-md-12 col-sm-12">
              {feedback && (
                <Feedback
                  feedbackLookup={feedbackLookup}
                  submitFeedback={onSubmitFeedback}
                  minimalFeedback={minimalFeedback}
                  t={t}
                />
              )}
              {props?.userType === userTypeEnums.CUSTOMER ? (
                <InterpreterBlock interpreter={interpreter} />
              ) : (
                <Travel travel={travel} onClick={handleReportClick} />
              )}
              {props?.userType === userTypeEnums.INTERPRETER && (
                <FileUploadWrapper Id={assignment?.assignmentId ?? ''} documentType={documentLookup ?? []}/>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div className="mt-4">
            <p className="blue font_700 font_18">{t('documentList.title')}</p>
          </div>
        </div>
        <DocumentListTableWrapper t={t} documentList={document} />
        <div className="d-flex">
          <div className="mt-4">
            <p className="blue font_700 font_18">{t('assignment.table.assignmentTransactions')}</p>
          </div>
          <div className="mt-4 ms-auto">
            <p className="blue font_700 font_18">
              {props?.userType === userTypeEnums.CUSTOMER
                ? `${t('assignment.sumInvoice')} ${assignment?.sumInvoice}`
                : `${t('assignment.SumSalary')} ${assignment?.sumPayout}`}
            </p>
          </div>
        </div>
        <TranscationTable transaction={transaction} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { assignment, common, order } = state;
  return {
    loading: assignment.loading || common.loading || order.loading,
    response: assignment.assignmentDetail,
    error: assignment.error || common.error,
    errorResponse: assignment.errorResponse || common.errorResponse,
    markFav: common.markResponse,
    cancelResponse: assignment.cancelAssignment,
    report: assignment.reportAssignment,
    lookupFeedback: common.feedbackLookup,
    submitFeedback: common.addFeedbackResponse,
    lookupDocument: assignment.documentLookup
  };
}

export const AssignmentWrapper = connect(mapStateToProps)(Assignment);
