import React, { useEffect, useState } from 'react';
import loginLogo from '../../assets/images/log-in.svg';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loader } from '../Loader';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { errorAlert, successAlert } from '../../uiComponents';
import {forgotPassword, resetForgotPassword} from '../../redux/action'
import { Link } from 'react-router-dom';
import './forgot-password.css'

export const ForgotPassword = (props) => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const dispatch = useAppDispatch();
  let loading = props.loading;

  const [email, setEmail] = useState('');
  const [errorForgot, setErrorForgot] = useState(false);

  useEffect(() => {
    localStorage.clear();
    return () => {
      resetForgotPassword(dispatch);
    };
  }, []);

  useEffect(() => {
    if (props?.response?.status) {
      if (props?.response?.status === 200) {
        successAlert(`${props?.response?.message}, ${t('login.newPasswordSentMessage')}`);
        navigate('/');
      } else {
        errorAlert(props?.response?.message);
      }
    }
  }, [props.response]);

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
    setErrorForgot(false);
  };

  const handleForgotClick = () => {
    if (email !== '') {
      forgotPassword(email, dispatch);
    } else {
      setErrorForgot(true);
    }
  };
  return (
    <section className="bg-image">
      <div className="container">
        <div className="row justify-content-center text-center">
          <div className="col-10 col-xl-5 col-lg-5 col-md-6 col-sm-10">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="logoin_image mt-5">
                  <a href="/">
                    <img src={loginLogo} alt="" />
                  </a>
                </div>
              </div>
            </div>
            <div className="login_box text-start mt-5">
              <div style={{ textAlign: 'center' }}>{}</div>
              <div className="mb-4">
                <label htmlFor="disabledSelect" className="form-label blue font_12">
                {t('forgotPassword.email')}
                </label>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Control type="email" placeholder={t('forgotPassword.placeholder')} onChange={onChangeEmail} />
                  {errorForgot && (
                    <Form.Text id="passwordHelpBlock" muted className='error'>
                      {t('forgotPassword.infoText')}
                    </Form.Text>
                  )}
                </Form.Group>
              </div>
              <div className="place-order-hover">
                <button type="button" className="btn accept w-100" onClick={handleForgotClick}>
                {t('forgotPassword.continue')}
                </button>
              </div>
              <div className="text-center mt-3">
                <Link to="/">
                  <span className="blue">{t('login.backToLogin')}</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {loading ? <Loader /> : null}
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return {
    loading: user.loading,
    response: user.forgotPassword,
    error: user.error,
    errorResponse: user.errorResponse,
  };
}

export const ForgotPasswordWrapper = connect(mapStateToProps)(ForgotPassword);
