import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { warnAlert } from '../ToastAlert';
import { useTranslation } from 'react-i18next';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { connect } from 'react-redux';
import { Loader } from '../../components/Loader';
import { useAppDispatch } from '../../config/hook';
import { userTypeEnums } from '../../interface';
import { getStatusReasonlookup, exportToExcel, resetExcelExport } from '../../redux/action';
import { fromDateToString, DateFormats, exportToExcelAndDownload } from '../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

export const CommonFilter = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusValue, setStatusValue] = useState(null);
  const [statusReasonList, setStatusReasonList] = useState<any[]>([]);
  const [internalLoading, setInternalLoading] = useState<boolean>(false);

  useEffect(() => {
    getStatusReasonlookup(null, dispatch);
  }, []);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responsePayload = props?.response;
      if (responsePayload.status === 200) {
        setStatusReasonList(responsePayload.data);
      }
    }
  }, [props.response]);

  useEffect(() => {
    (async () => {
      if (Object.keys(props?.excelPayload).length > 0) {
        const responsePayload = props?.excelPayload;
        if (responsePayload.status === 200) {
          if (responsePayload.data.assignment.length > 0) {
            await exportToExcelAndDownload(responsePayload?.data?.assignment);
            resetExcelExport(dispatch);
          } else {
            warnAlert(t('excel.emptyExcelWarning'));
          }
          setInternalLoading(false);
        }
      }
    })();
  }, [props.excelPayload]);

  const commonUpdate = (start, end, statusValue) => {
    const dates = {
      fromDate: start ? fromDateToString(start, DateFormats.DYNAMICSEARCHFORMAT) : null,
      toDate: end ? fromDateToString(end, DateFormats.DYNAMICSEARCHFORMAT) : null,
      statusReason: statusValue === 'default' ? null : statusValue,
    };
    props.updateCommon(dates);
  };
  const handleStartDate = (date) => {
    setStartDate(date);
    setEndDate(date);
    commonUpdate(date, date, statusValue);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
    commonUpdate(startDate, date, statusValue);
  };

  const onChangeStatus = (e) => {
    setStatusValue(e.target.value);
    commonUpdate(startDate, endDate, e.target.value === 'default' ? null : e.target.value);
  };

  const handleExportToExcel = () => {
    setInternalLoading(true);
    const exportPayload = {
      ...props.historyFilter,
      pageIndex: 0,
      pageSize: 0,
    };
    const userType = props?.userType === userTypeEnums.CUSTOMER ? 'customer' : 'interpreter';
    exportToExcel(exportPayload, dispatch, userType);
  };

  if (internalLoading) return <Loader />;

  return (
    <>
      <div className="col-12 col-xl-2 col-lg-2 col-md-4 col-sm-8 mt-2 ms-4 ms-md-0">
        <div className="dropdown status_dropdown">
          <label className="form-label blue">{t('dashboard.statusReason')}</label>
          <Form.Select className="select-status" onChange={onChangeStatus}>
            <option value={'default'}>{t('dashboard.statusReasonDefault')}</option>
            {statusReasonList.map((el, index) => (
              <option value={el.id} key={el.id}>
                {el.name}
              </option>
            ))}
          </Form.Select>
        </div>
      </div>
      <div className="col-12 col-xl-2 col-lg-2 col-md-4 col-sm-8 mt-2 ms-4 ms-md-0">
        <div className="dropdown status_dropdown">
          <label className="form-label blue">{t('dashboard.startDate')}</label>
          <DatePicker
            selected={startDate}
            onChange={handleStartDate}
            className={'form-control date-time'}
            dateFormat="dd.MM.yyyy"
          />
        </div>
      </div>
      <div className="col-12 col-xl-2 col-lg-2 col-md-4 col-sm-8 mt-2 ms-4 ms-md-0">
        <div className="dropdown status_dropdown">
          <label className="form-label blue">{t('dashboard.endDate')}</label>
          <DatePicker
            selected={endDate}
            onChange={handleEndDate}
            className={'form-control date-time'}
            dateFormat="dd.MM.yyyy"
            minDate={startDate}
          />
        </div>
      </div>
      <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6">
        <div className="mb-3 ms-4 mt-2">
          <label className="form-label blue">{t('dashboard.export')}</label>
          <div className="ms-auto">
            <span className="place-order-hover me-3">
              <button type="button" className="btn header-button pe-4 ps-4" onClick={handleExportToExcel}>
                <RiFileExcel2Fill size={'1.5rem'} title="Export to Excel" />
              </button>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { assignment } = state;
  return {
    loading: assignment.insideLoading,
    response: assignment.statusReasonlookup,
    error: assignment.error,
    errorResponse: assignment.errorResponse,
    historyFilter: assignment.filterHistory,
    excelPayload: assignment.exportToExcel,
  };
}

export const CommonFilterWrapper = connect(mapStateToProps)(CommonFilter);
