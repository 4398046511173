import React, { useState, useEffect, useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';
import { SubHeader, warnAlert } from '../../uiComponents';
import { useAppDispatch } from '../../config/hook';
import { useTranslation } from 'react-i18next';
import { Loader } from '../Loader';
import { getOrderlookup, createOrder, resetOrder, editOrder } from '../../redux/action';
import {
  GlobalContext,
  fromDateToString,
  fromStringToDate,
  DateFormats,
  filterKeyFromName,
  checkTodaysDate,
  startTimeValidation,
} from '../../utils';
import { ICreateOrder } from '../../interface';
import { useDropzone } from 'react-dropzone';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import fileUpload from '../../assets/images/upload-document.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './order.css';

const maxDateInstance = new Date();

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const Order = (props) => {
  const futureDate = new Date(new Date().getTime() + 5 * 60000);
  let userResponse: any = JSON.parse(localStorage.getItem('userSettings'));
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const global = useContext(GlobalContext);
  const leftContent = {
    title: t('order.createTitle'),
    breadcrumb: '',
  };
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(futureDate);
  const [lookUp, setLookUp] = useState<any>({});

  const [poReference, setPoReference] = useState('');
  const [poReferenceValid, setPoReferenceValid] = useState<boolean>(false);

  const [selectedAssignmentType, setSelectedAssignmentType] = useState<any>(0);
  const [assignTypeInvalid, setAssignTypeInvalid] = useState<boolean>(false);

  const [onsiteAddress, setOnsiteAddress] = useState<any>(0);
  const [onsiteFreeText, setOnsiteFreeText] = useState('');

  const [language, setLanguage] = useState(0);
  const [languageInvalid, setLanguageInvalid] = useState<boolean>(false);

  const [interpreter, setInterpreter] = useState(0);
  const [interpreterInvalid, setInterpreterInvalid] = useState<boolean>(false);

  const [preferInterpreterName, setPreferInterpreterName] = useState('');

  const [gender, setGender] = useState(206500000);

  const [evalSwitch, setEvalSwitch] = useState(false);

  const [multiInterpreter, setMultiInterpreter] = useState(false);

  const [quantity, setQuantity] = useState<number>(null);
  const [quantityInvalid, setQuantityInvalid] = useState<boolean>(false);

  const [frequencySwitch, setFrequencySwith] = useState(false);

  const [frequency, setFrequency] = useState<any>(0);
  const [FreqEndDate, setFreqEndDate] = useState(new Date());

  const [skill, setSkill] = useState<any>(0);
  const [skillInvalid, setSkillInvalid] = useState<boolean>(false);

  const [info, setInfo] = useState('');

  const [adContact, setAdContact] = useState(0);

  const [fileType, setFileType] = useState([]);

  const [customFileName, setCustomFileName] = useState([]);

  const [duration, setDuration] = useState<any>(0);
  const [durationInvalid, setDurationInvalid] = useState<boolean>(false);

  const [files, setFiles] = useState([]);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [assignmentId, setAssignmentId] = useState<string>('');

  const [onSubmitStartTimeValid, setOnSubmitStartTimeValid] = useState<boolean>(true);
  const [minDateInstance, setMinDateInstance] = useState(new Date());

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (acceptedFiles.length > 0) {
      setFiles(acceptedFiles);
    } else if (fileRejections.length > 0) {
      fileRejections.map(({ file }) => {
        warnAlert(`${file.name} not allowed`);
      });
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/*': ['.pdf', '.xlsx', '.xls', '.docx', '.doc'],
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
  });

  useEffect(() => {
    if (global.relatedCustomer !== '') {
      const requestPayload = {
        customerId: global.relatedCustomer === 'default' ? props?.userId : global.relatedCustomer,
        orderByContactId: props?.userId,
      };
      getOrderlookup(requestPayload, dispatch);
    }
  }, [global.relatedCustomer]);

  const parseEdit = (editAssignment: any, lookupValue) => {
    setPoReference(editAssignment?.referenceNo);
    setPreferInterpreterName(editAssignment?.preferredinterpreterName);
    if (editAssignment?.assignmentType) {
      const assignmentTypeValue = filterKeyFromName(lookupValue?.assignmentTypes, {
        name: editAssignment?.assignmentType,
      });
      if (assignmentTypeValue !== null) {
        // @ts-ignore
        setSelectedAssignmentType(assignmentTypeValue?.id);
      }
    }
    if (editAssignment?.language) {
      const languageValue = filterKeyFromName(lookupValue?.languages, { name: editAssignment?.language });
      if (languageValue !== null) {
        // @ts-ignore
        setLanguage(languageValue?.id);
      }
    }
    if (editAssignment?.duration !== '') {
      setDuration(parseInt(editAssignment?.duration));
    }
    if (editAssignment?.startTime && editAssignment?.startTime !== '') {
      setStartDate(fromStringToDate(editAssignment?.startTime, DateFormats.APIFORMAT).toDate());
      setStartTime(fromStringToDate(editAssignment?.startTime, DateFormats.APIFORMAT).toDate());
    }
    // Prefered Interpreter left because data is not yet added in assignment details api
    if (editAssignment?.preferredinterpreter) {
      const interpreterValue = filterKeyFromName(lookupValue?.prefferedInterpreters, {
        name: editAssignment?.preferredinterpreter,
      });
      if (interpreterValue !== null) {
        // @ts-ignore
        setInterpreter(interpreterValue?.id);
      }
    }
    // Repete Assignment is also same
    if (editAssignment?.impartialityEval) {
      setEvalSwitch(editAssignment?.impartialityEval);
    }
    if (editAssignment?.gender) {
      const genderValue = filterKeyFromName(lookupValue?.genders, { name: editAssignment?.gender });
      if (genderValue !== null) {
        // @ts-ignore
        setGender(genderValue?.id);
      }
    }
    if (editAssignment?.skillLevel) {
      const skillValue = filterKeyFromName(lookupValue?.skillLevels, { name: editAssignment?.skillLevel });
      if (skillValue !== null) {
        // @ts-ignore
        setSkill(skillValue?.id);
      }
    }
    if (editAssignment?.additionalInfo) {
      setInfo(editAssignment?.additionalInfo);
    }
    if (editAssignment?.additionalContact) {
      const additionalContact = filterKeyFromName(lookupValue?.additionalContacts, {
        name: editAssignment?.additionalContact,
      });
      if (additionalContact !== null) {
        // @ts-ignore
        setAdContact(additionalContact?.id);
      }
    }
    if (editAssignment?.assignmentId) {
      setAssignmentId(editAssignment?.assignmentId);
    }
    if (editAssignment?.onSiteAddress) {
      const onSiteAddressValue = filterKeyFromName(lookupValue?.onsiteAddresses, {
        name: editAssignment?.onSiteAddress,
      });
      if (onSiteAddressValue !== null) {
        // @ts-ignore
        setOnsiteAddress(onSiteAddressValue?.id);
      }
    }
    if (editAssignment?.additonalOnsiteInfo) {
      setOnsiteFreeText(editAssignment?.additonalOnsiteInfo);
    }
    setIsEdit(true);
  };

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      if (props?.response?.status === 201 || props?.response?.status === 200) {
        resetOrder(dispatch);
        navigate(`/view/${props?.response?.data}`);
      }
    }

    if (Object.keys(props?.lookup).length > 0) {
      setLookUp(props?.lookup?.data);
      if (location?.state) {
        const editState: any = location?.state;
        parseEdit(editState, props?.lookup?.data);
      }
      if (props?.lookup?.data?.defaultSkillLevel?.id) {
        setSkill(props?.lookup?.data?.defaultSkillLevel?.id);
      }
      if (props?.lookup?.data?.poReference) {
        setPoReference(props?.lookup?.data?.poReference);
      }
    }
  }, [props.response, props.lookup]);

  useEffect(() => {
    if (checkTodaysDate(startDate?.toString())) {
      setStartTime(futureDate);
    }
  }, [startDate]);

  const handlePoReference = (e: any) => {
    setPoReference(e.target.value);
    if (e.target.value === '' && lookUp?.isPORequired) {
      setPoReferenceValid(true);
    } else {
      setPoReferenceValid(false);
    }
  };

  const handleAssignmenttype = (e: any) => {
    setSelectedAssignmentType(e.target.value);
    if (e.target.value === 0) {
      setAssignTypeInvalid(true);
    } else {
      setAssignTypeInvalid(false);
    }
  };

  const handleOnSiteAddress = (e: any) => {
    setOnsiteAddress(e.target.value);
  };

  const handleFrquency = (e: any) => {
    setFrequency(e.target.value);
  };

  const handleOnSiteFreeText = (e: any) => {
    setOnsiteFreeText(e.target.value);
  };

  const handlePreferInterpreterName = (e: any) => {
    setPreferInterpreterName(e.target.value);
  };

  const handleLanguage = (e: any) => {
    setLanguage(e.target.value);
    if (e.target.value === 0) {
      setLanguageInvalid(true);
    } else {
      setLanguageInvalid(false);
    }
  };

  const handleInterpreter = (e: any) => {
    setInterpreter(e.target.value);
    if (e.target.value === 0) {
      setInterpreterInvalid(true);
    } else {
      setInterpreterInvalid(false);
    }
  };

  const handleGender = (e: any) => {
    setGender(e.target.value);
  };

  const handleSwitch = () => {
    setEvalSwitch(!evalSwitch);
  };

  const handleMultiInterpreterSwitch = () => {
    setMultiInterpreter(!multiInterpreter);
    if (multiInterpreter === false) {
      setQuantityInvalid(false);
    }
  };

  const handleQuantity = (e: any) => {
    setQuantity(e.target.value);
    if (e.target.value === '' || e.target.value < 2) {
      setQuantityInvalid(true);
    } else {
      setQuantityInvalid(false);
    }
  };

  const handleSkill = (e: any) => {
    setSkill(e.target.value);
    if (e.target.value === 0) {
      setSkillInvalid(true);
    } else {
      setSkillInvalid(false);
    }
  };

  const handleAdditionaInfo = (e: any) => {
    setInfo(e.target.value);
  };

  const handleAdditionalContact = (e: any) => {
    setAdContact(e.target.value);
  };

  const handleFileType = (e: any, index: number) => {
    const updateFileType = [...fileType];
    if (e.target.value == 0) {
      updateFileType[index] = null;
      setFileType(updateFileType);
    } else {
      updateFileType[index] = e.target.value;
      setFileType(updateFileType);
    }
  };

  const handleCustomFileName = (e: any, index: number) => {
    const updateCustomFileName = [...customFileName];
    updateCustomFileName[index] = e.target.value;
    setCustomFileName(updateCustomFileName);
  };

  const handleDuration = (e: any) => {
    setDuration(e.target.value);
    if (e.target.value === '') {
      setDurationInvalid(true);
    } else {
      setDurationInvalid(false);
    }
  };

  const handleFrequencySwitch = () => {
    setFrequencySwith(!frequencySwitch);
    setFreqEndDate(startDate);
  };

  const handleSubmit = async () => {
    let isOnsite = false;
    if (selectedAssignmentType == 0) {
      setAssignTypeInvalid(true);
    }
    if (language == 0) {
      setLanguageInvalid(true);
    }

    if (skill == 0) {
      setSkillInvalid(true);
    }

    if (duration == 0 || duration === '') {
      setDurationInvalid(true);
    }

    if (selectedAssignmentType === 'e337f404-294c-ec11-8c62-000d3adf5ec2') {
      isOnsite = true;
    }

    if (poReference === '' && lookUp?.isPORequired) {
      setPoReferenceValid(true);
      return 0;
    }

    if (multiInterpreter && quantity < 2) {
      setQuantityInvalid(true);
      return 0;
    }

    if (files.length > 0) {
      const updateFileType = [...fileType];
      if (fileType.length === 0) {
        setFileType(updateFileType);
        return 0;
      } else {
        for (let index = 0; index < files.length; index++) {
          if (fileType[index] === null || fileType[index] === undefined) {
            setFileType(updateFileType);
            return 0;
          }
        }
      }
    }

    if (!startTimeValidation(startTime) && checkTodaysDate(startDate.toString())) {
      setOnSubmitStartTimeValid(false);
      setMinDateInstance(new Date());
      return 0;
    }

    if (selectedAssignmentType != 0 && language != 0 && skill != 0 && duration != 0 && duration !== '') {
      const orderPayload: ICreateOrder = {
        POReferenceNo: poReference,
        AssignmentTypeId: selectedAssignmentType == 0 ? null : selectedAssignmentType,
        LanguageId: language == 0 ? null : language,
        DurationInMinutes: duration,
        StartDateTime: `${fromDateToString(startDate, DateFormats.DATE)} ${fromDateToString(
          startTime,
          DateFormats.TIME,
        )}`,
        PreferredInterpreterName: preferInterpreterName,
        PreferredInterpreterId: interpreter == 0 ? '' : interpreter,
        IsImpartialityEvalRequired: evalSwitch,
        GenderId: gender == 0 ? null : gender,
        SkillLevelId: skill,
        AdditionalInfo: info,
        OrderByContactId: props?.userId,
        AdditionalContactId: adContact == 0 ? '' : adContact,
        CustomerId: global.relatedCustomer === 'default' ? props?.userId : global.relatedCustomer,
        ...(assignmentId !== '' && { AssignmentId: assignmentId }),
        ...(frequencySwitch && { Frequency: frequency == 0 ? '' : frequency }),
        ...(frequencySwitch && { RepeatAssignmentToDate: fromDateToString(FreqEndDate) }),
        ...(isOnsite && { OnSiteAddessInfo: onsiteFreeText }),
        ...(isOnsite && { DefaultOnSiteAddessId: onsiteAddress }),
        IsRepeatAssignment: frequencySwitch,
        IsMultiple: multiInterpreter,
        ...(multiInterpreter && { Quantity: quantity }),
      };
      let fileArray = [];
      files.map((value, index) => {
        let document = {
          name: customFileName[index] || value?.name,
          documentTypeId: fileType[index],
          file: value,
        };
        fileArray.push(document);
      });
      if (isEdit) {
        if (assignmentId === null || assignmentId === '') {
          warnAlert('Assignment has some missing fields. Please refresh or edit again from assigment page');
        } else {
          editOrder(orderPayload, fileArray, dispatch);
        }
      } else {
        createOrder(orderPayload, fileArray, dispatch);
      }
    }
  };

  return (
    <div className="bg_blue p-3 content">
      <div className="mt-2 pb-2">
        <SubHeader isDisplay={false} leftContent={leftContent} />
        {props.loading && <Loader />}
        <div className="bg-white p-3">
          <div className="row">
            <div className="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
              <form>
                <div className="row">
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.poReference')}</label>
                      <Form.Control
                        required={lookUp?.isPORequired}
                        type="text"
                        placeholder={t('order.placeholderPoReference')}
                        onChange={handlePoReference}
                        isInvalid={poReferenceValid}
                        defaultValue={poReference}
                      />
                      <Form.Control.Feedback type="invalid">
                        {t('order.require.poReferenceRequire')}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.assignmentType')}</label>
                      <Form.Select onChange={handleAssignmenttype} isInvalid={assignTypeInvalid}>
                        <option value={0}>{t('order.assignmentTypePlaceHolder')}</option>
                        {lookUp?.assignmentTypes &&
                          lookUp?.assignmentTypes.length > 0 &&
                          lookUp?.assignmentTypes.map((el) => (
                            <option value={el.id} key={el.id} selected={el.id === selectedAssignmentType}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {t('order.require.assignmentTypeRequire')}
                      </Form.Control.Feedback>
                    </div>
                  </div>
                  {selectedAssignmentType === 'e337f404-294c-ec11-8c62-000d3adf5ec2' && (
                    <>
                      <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="mb-4">
                          <label className="form-label blue font_12">{t('order.onsiteAddress')}</label>
                          <Form.Select onChange={handleOnSiteAddress}>
                            <option value={0}>{t('order.onsiteAddressPlaceHolder')}</option>
                            {lookUp?.onsiteAddresses &&
                              lookUp?.onsiteAddresses.length > 0 &&
                              lookUp?.onsiteAddresses.map((el) => (
                                <option value={el.id} key={el.id} selected={el.id === onsiteAddress}>
                                  {el.name}
                                </option>
                              ))}
                          </Form.Select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-4">
                          <label className="form-label blue font_12">{t('order.onsiteInfo')}</label>
                          <textarea
                            onChange={handleOnSiteFreeText}
                            className="form-control"
                            id="floatingTextarea2"
                            placeholder={t('order.placeholderOnsiteInfo')}
                            style={{ height: '100px' }}
                            defaultValue={onsiteFreeText}></textarea>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="mb-4">
                      <div className="row">
                        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label className="form-label blue font_12">{t('order.language')}</label>
                          <Form.Select onChange={handleLanguage} isInvalid={languageInvalid}>
                            <option selected value={0}>
                              {t('order.languagePlaceHolder')}
                            </option>
                            {lookUp?.languages &&
                              lookUp?.languages.length > 0 &&
                              lookUp?.languages.map((el) => (
                                <option value={el.id} key={el.id} selected={el.id === language}>
                                  {el.name}
                                </option>
                              ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {t('order.require.languageRequire')}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <div className="row">
                        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label className="form-label blue font_12">{t('order.startAt')}</label>
                          <div className="input-group flex-nowrap">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(date);
                                setFreqEndDate(date);
                                setOnSubmitStartTimeValid(true);
                              }}
                              className={'form-control date-time'}
                              dateFormat="dd.MM.yyyy"
                              minDate={new Date()}
                              calendarStartDay={1}
                            />
                          </div>
                        </div>
                        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label className="form-label blue font_12">{t('order.startTime')}</label>
                          <div className="input-group flex-nowrap">
                            <DatePicker
                              selected={startTime}
                              className={classNames('form-control', {
                                'date-time': onSubmitStartTimeValid,
                                'date-time-error': !onSubmitStartTimeValid,
                              })}
                              onChange={(date) => {
                                setStartTime(date);
                                setOnSubmitStartTimeValid(true);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              minTime={
                                checkTodaysDate(startDate.toString())
                                  ? minDateInstance.setHours(minDateInstance.getHours(), minDateInstance.getMinutes())
                                  : null
                              }
                              maxTime={checkTodaysDate(startDate.toString()) ? maxDateInstance.setHours(23, 55) : null}
                              timeIntervals={5}
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.interpreter')}</label>
                      <Form.Select onChange={handleInterpreter} isInvalid={interpreterInvalid}>
                        <option selected value={0}>
                          {t('order.interpreterPlaceHolder')}
                        </option>
                        {lookUp?.prefferedInterpreters &&
                          lookUp?.prefferedInterpreters.length > 0 &&
                          lookUp?.prefferedInterpreters.map((el) => (
                            <option value={el.id} key={el.id} selected={el.id === interpreter}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Select>
                      {/* <Form.Control.Feedback type="invalid">Interpreter is required</Form.Control.Feedback> */}
                    </div>
                  </div>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="mb-4">
                      <div className="row">
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                          <label className="form-label blue font_12">{t('order.duration')}</label>
                          <Form.Control
                            className="custom-number"
                            type="number"
                            placeholder={t('order.minutes')}
                            onChange={handleDuration}
                            value={duration == 0 ? undefined : duration}
                            isInvalid={durationInvalid}
                          />
                          <Form.Control.Feedback type="invalid">
                            {t('order.require.durationRequire')}
                          </Form.Control.Feedback>
                        </div>
                        <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                          <label className="form-label blue font_12">{t('order.preferInterpreterName')}</label>
                          <div className="input-group flex-nowrap">
                            <Form.Control
                              type="text"
                              placeholder={t('order.preferInterpreterName')}
                              onChange={handlePreferInterpreterName}
                              defaultValue={preferInterpreterName}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <Stack direction="horizontal" gap={1}>
                        <div>
                          <label className="form-label blue font_12">{t('order.repete')}</label>
                          <div>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              onChange={handleFrequencySwitch}
                              checked={frequencySwitch}
                            />
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <div className="row">
                        {frequencySwitch && (
                          <>
                            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 right_border">
                              <label className="form-label blue font_12">{t('order.repeteFrequency')}</label>
                              <div className="input-group flex-nowrap">
                                <Form.Select onChange={handleFrquency}>
                                  <option value={0}>{t('order.repeteFrequencyPlaceHolder')}</option>
                                  <option value={'Daily'}>{t('order.frequencyDaily')}</option>
                                  <option value={'Weekly'}>{t('order.frequencyWeekly')}</option>
                                </Form.Select>
                              </div>
                            </div>
                            <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 right_border">
                              <label className="form-label blue font_12">{t('order.repeteUntil')}</label>
                              <div className="flex-nowrap">
                                <DatePicker
                                  selected={FreqEndDate}
                                  onChange={(date) => setFreqEndDate(date)}
                                  className={'form-control date-time'}
                                  dateFormat="dd.MM.yyyy"
                                  minDate={startDate}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <Stack direction="horizontal" gap={1}>
                        <div>
                          <label className="form-label blue font_12">{t('order.multipleInterpreter')}</label>
                          <div>
                            <Form.Check
                              type="switch"
                              id="custom-switch"
                              onChange={handleMultiInterpreterSwitch}
                              checked={multiInterpreter}
                            />
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <div className="row">
                        <div>
                          {multiInterpreter && (
                            <>
                              <label className="form-label blue font_12">{t('order.quantity')}</label>
                              <Form.Control
                                type="number"
                                placeholder={t('order.quantity')}
                                onChange={handleQuantity}
                                isInvalid={quantityInvalid}
                              />
                              <Form.Control.Feedback type="invalid">{t('order.quantityMessage')}</Form.Control.Feedback>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.evalRequire')}</label>
                      <div>
                        <Form.Check type="switch" id="custom-switch" onChange={handleSwitch} checked={evalSwitch} />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.gender')}</label>
                      <Form.Select onChange={handleGender} defaultValue={206500000}>
                        <option value={206500000} hidden>
                          {t('order.select')}
                        </option>
                        {lookUp?.genders &&
                          lookUp?.genders.length > 0 &&
                          lookUp?.genders.map((el) => (
                            <option value={el.id} key={el.id} selected={el.id === gender}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.skillLevel')}</label>
                      <Form.Select onChange={handleSkill} isInvalid={skillInvalid}>
                        <option selected value={0}>
                          {t('order.select')}
                        </option>
                        {lookUp?.skillLevels &&
                          lookUp?.skillLevels.length > 0 &&
                          lookUp?.skillLevels.map((el) => (
                            <option value={el.id} key={el.id} selected={el.id === skill}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{t('order.require.skillRequire')}</Form.Control.Feedback>
                    </div>
                  </div>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-4">
                    <label className="form-label blue font_12">{t('order.additionalInfo')}</label>
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        id="floatingTextarea2"
                        placeholder={t('order.placeholderAdditionalInfo')}
                        style={{ height: '100px' }}
                        onChange={handleAdditionaInfo}
                        defaultValue={info}></textarea>
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4 gray_input">
                      <label className="form-label blue font_12">{t('order.orderByContact')}</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Adamsmith"
                        value={userResponse?.name}
                        aria-label="default input example"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="mb-4">
                      <label className="form-label blue font_12">{t('order.additionalContact')}</label>
                      <Form.Select onChange={handleAdditionalContact}>
                        <option selected value={0}>
                          {t('order.select')}
                        </option>
                        {lookUp?.additionalContacts &&
                          lookUp?.additionalContacts.length > 0 &&
                          lookUp?.additionalContacts.map((el) => (
                            <option value={el.id} key={el.id} selected={adContact === el.id}>
                              {el.name}
                            </option>
                          ))}
                      </Form.Select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <label className="form-label blue font_12">{t('order.documentTitle')}</label>
              <div {...getRootProps()} className="drop-file">
                <img src={fileUpload} alt="" />
                <input {...getInputProps()} className="dropzone" />
                <p>{t('order.documentInfoText')}</p>
              </div>
              <aside>
                <label className="form-label blue font_12">{t('order.documentAccept')}</label>
                {files.map((file, index) => (
                  <Stack direction="horizontal" gap={2} key={index}>
                    <Form.Control type="text" placeholder={file.name} readOnly />
                    <div className="vr" />
                    <div className="custom-filetype">
                      <Form.Select
                        onChange={(e) => {
                          handleFileType(e, index);
                        }}
                        isInvalid={
                          files.length > 0
                            ? fileType[index] === null || fileType[index] === undefined
                              ? true
                              : false
                            : false
                        }>
                        <option value={0}>{t('order.fileType')}</option>
                        {lookUp?.documentTypes.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{t('order.require.fileTypeRequire')}</Form.Control.Feedback>
                    </div>
                    <div className="vr" />
                    <input
                      className="form-control"
                      type="text"
                      placeholder={t('order.customFileName')}
                      aria-label="default input example"
                      onChange={(e) => {
                        handleCustomFileName(e, index);
                      }}
                    />
                  </Stack>
                ))}
              </aside>
            </div>
          </div>
          <div className="place-order">
            <div className="row">
              <div className="col-3 mt-5 mb-5 place-order-hover">
                <button type="button" className="btn accept w-100" onClick={handleSubmit}>
                  {isEdit ? t('order.editOrderButton') : t('order.orderButton')}
                </button>
              </div>
              {!onSubmitStartTimeValid && (
                <div className="col-3 mt-5 mb-5 error"> {t('order.require.startTimeError')}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  const { order } = state;
  return {
    loading: order.loading,
    response: order.response,
    error: order.error,
    errorResponse: order.errorResponse,
    lookup: order.lookupResponse,
  };
}

export const OrderWrapper = connect(mapStateToProps)(Order);
