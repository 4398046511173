import moment from 'moment';

export enum DateFormats {
  DATEWITHTIME = 'DD.MM.YYYY HH:mm',
  DATE = 'DD.MM.YYYY',
  DATEWITHTIME24FORMAT = 'DD.MM.YYYY HH:mm',
  APIFORMAT = 'MM/DD/YYYY HH:mm A',
  DYNAMICSEND24FORMAT = 'YYYY.MM.DD HH:mm',
  DYNAMICSEARCHFORMAT = 'YYYY.MM.DD',
  TIME = 'HH:mm',
}

export const formatDate = (rawDate: string, format = DateFormats.DATEWITHTIME) => {
  const convertInUTC = moment.utc(rawDate);
  return moment(convertInUTC).format(format);
};

export const fromStringToDate = (rawDate: string, format = DateFormats.DATEWITHTIME) => {
  //const convertInUTC = moment.utc(rawDate);
  return moment(rawDate, format);
};

export const fromDateToString = (rawDate: Date, format = DateFormats.DATEWITHTIME24FORMAT) => {
  return moment(rawDate).format(format);
};

export const checkIsSameOrbefore = (compareDate: string, inputDate = moment()) => {
  const local = moment(inputDate).format('X');
  const start = moment(compareDate).format('X');
  return local <= start;
};

export const checkEndDateWithCurrent = (compareDate: string, inputDate = moment()) => {
  const current = moment(inputDate).format('X');
  const end = moment(compareDate).format('X');
  return current > end;
};

export const checkTodaysDate = (compareDate: string, inputDate = new Date()) => {
  const today = moment(inputDate).format(DateFormats.DATE);
  const chosenDate = moment(compareDate).format(DateFormats.DATE);
  return today === chosenDate;
};

export const startTimeValidation = (compareTime:Date, currentTime = moment().unix())=>{
  return moment(moment(compareTime).unix()).isAfter(currentTime);
}
