import React, { useState, useEffect, useContext } from 'react';
import { SubHeader } from '../../uiComponents';
import { useTranslation } from 'react-i18next';
import { DataTableBase, EmptyDatatable, DateFormatForSpecificField, ModelPopup } from '../../uiComponents';
import { connect } from 'react-redux';
import { useAppDispatch } from '../../config/hook';
import { DataTableLoader } from '../Loader';
import { RemoveUnAvailabilityWrapper } from './RemoveUnAvailability';
import { getUnavailableInterpreterList } from '../../redux/action';
import i18n from '../../i18n/config';
import { GlobalContext } from '../../utils';
import { AddUnavailabilityWrapper } from './AddUnavailability';

const columns = [
  {
    name: i18n.t('unAvailability.table.name'),
    selector: (row) => row.name,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('unAvailability.table.start'),
    selector: (row) => <DateFormatForSpecificField row={row} filedName={'fromDate'} />,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('unAvailability.table.end'),
    selector: (row) => <DateFormatForSpecificField row={row} filedName={'toDate'} />,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('unAvailability.table.frequency'),
    selector: (row) => row.frequency,
    sortable: true,
    wrap: true,
    center: true,
  },
  {
    name: i18n.t('unAvailability.table.remove'),
    selector: (row) => <RemoveUnAvailabilityWrapper row={row} />,
    sortable: false,
    wrap: true,
    center: true,
  },
];

export const UnAvailability = (props) => {
  const global = useContext(GlobalContext);
  const { t } = useTranslation();
  const leftContent = {
    title: t('unAvailability.title'),
    breadcrumb: '',
  };
  const [list, setList] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [isAddClick, setIsAddClick] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    getUnavailableInterpreterList(props?.userId, dispatch);
  }, [global.trigger]);

  useEffect(() => {
    if (Object.keys(props?.response).length > 0) {
      const responsePayload = props?.response?.data;
      setList(responsePayload ?? []);
    }
  }, [props.response]);

  const onClick = () => {
    setIsAddClick(true);
  };

  return (
    <div className="bg_blue p-3 content">
      <div className="mt-2 pb-2">
        <SubHeader
          isDisplay={false}
          leftContent={leftContent}
          addUnavailability
          UnavailabilityOnClick={() => {
            setModalShow(true);
          }}
        />
        <div className="bg-white p-3">
          <DataTableBase
            columns={columns}
            data={list}
            progressPending={props?.loading}
            progressComponent={<DataTableLoader />}
            noDataComponent={<EmptyDatatable message={t('unAvailability.table.empty')} />}
          />
        </div>
      </div>
      <ModelPopup
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setIsAddClick(false);
        }}
        size={'lg'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        heading={t('unAvailability.addButton')}
        body={
          <AddUnavailabilityWrapper
            isAddClick={isAddClick}
            userId={props?.userId}
            onCustomHide={() => {
              setModalShow(false);
              setIsAddClick(false);
            }}
          />
        }
        onAddClick={onClick}
      />
    </div>
  );
};

function mapStateToProps(state) {
  const { common } = state;
  return {
    loading: common.unavailableLoading,
    response: common.unavailabilityList,
    error: common.error,
    errorResponse: common.errorResponse,
  };
}

export const UnAvailabilityWrapper = connect(mapStateToProps)(UnAvailability);
